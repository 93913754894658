import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/details',
    name: 'Details',
    meta: { title: '资讯详情' },
    component: () => import('@/views/details'),
    hidden: true
  },
  {
    path: '/home',
    name: 'Home',
    meta: { title: '首页' },
    component: () => import('@/views/home')
  },
  {
    path: '/product',
    name: 'Product',
    meta: { title: '产品中心' },
    component: () => import('@/views/product/ai')
  },
  // {
  //   path: '/ai',
  //   name: 'Ai',
  //   meta: { title: '产品详细介绍', son: 1 },
  //   component: () => import('@/views/product/ai')
  // },
  // {
  //   path: '/internet',
  //   name: 'Internet',
  //   meta: { title: '城市互联网', son: 1 },
  //   component: () => import('@/views/product/internet')
  // },
  // {
  //   path: '/more',
  //   name: 'More',
  //   meta: { title: '更多产品', son: 1 },
  //   component: () => import('@/views/product/more')
  // },
  // {
  //   path: '/case',
  //   name: 'Case',
  //   meta: { title: '经典案例' },
  //   component: () => import('@/views/case')
  // },
  {
    path: '/news',
    name: 'News',
    meta: { title: '新闻资讯' },
    component: () => import('@/views/news')
  },

  {
    path: '/survey',
    name: 'Survey',
    meta: { title: '公司概况', redirect: 'Qualifications' }
  },
  {
    path: '/qualifications',
    name: 'Qualifications',
    meta: { title: '企业资质', son: 1 },
    component: () => import('@/views/survey/qualifications')
  },
  {
    path: '/partner',
    name: 'Partner',
    meta: { title: '合作伙伴', son: 1 },
    component: () => import('@/views/survey/partner')
  },
  {
    path: '/introduce',
    name: 'Introduce',
    meta: { title: '公司简介', son: 1 },
    component: () => import('@/views/survey/introduce')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: { title: '联系我们' },
    component: () => import('@/views/contact')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}
