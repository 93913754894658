<template>
  <!--面包屑-->
  <div>
    <div class="ul-box" v-show="listShow">
      <div class="xx-box" @click="guanbi()">
        <img class="xx" src="../assets/images/x.png" alt="" />
      </div>
      <div
        class="list-box"
        :class="{ lists: item.meta.son }"
        v-for="item in ul"
        :key="item.name"
        @click.stop="tiaozhuan(item)"
      >
        {{ item.meta.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { routes } from '@/router'

export default {
  name: 'Breadcrumb',
  data() {
    return {
      listShow: true,
      ul: []
    }
  },

  mounted() {
    this.ul = routes.splice(2, routes.length - 1)
  },
  methods: {
    guanbi() {
      this.$parent.hbbClick()
    },
    tiaozhuan(item) {
      this.guanbi()
      if (item.meta.redirect) {
        this.$router.push({
          name: item.meta.redirect
        })
      } else {
        this.$router.push({
          name: item.name
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.xx-box {
  height: 0.9rem;
  background: #fdfdfd;
}
.xx {
  width: 0.29rem;
  height: 0.29rem;
  float: right;
  margin-top: 0.31rem;
  margin-right: 0.2rem;
}
.ul-box {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: #f6f6f6;
  z-index: 900;
  font-size: 0.26rem;
  font-weight: bold;
  color: #333333;
  padding-bottom: 0.2rem;
  height: 100vh;
  overflow: auto;
}
.list-box {
  z-index: 900;
  font-size: 0.26rem;
  line-height: 1.3rem;
  margin: 0 0.3rem 0;
}
.list-box::after {
  height: 0.02rem;
  background: #d5d5d5;
  content: '';
  display: block;
}
.lists {
  height: 1.1rem;
  line-height: 1.1rem;
  margin-top: 0.2rem;
  background-color: #e8e8e8;
  text-indent: 1em;
}
</style>
