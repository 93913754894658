<template>
  <div class="box">
    <div id="navigation">
      <div class="logo" @click="shouye()">
        <img src="@/assets/images/logo@2x.png" alt="logo" class="logo-img" />
      </div>
      <div class="" @click="hbbClick">
        <img src="@/assets/images/list.png" alt="list" class="logo-list" />
      </div>
    </div>
    <Breadcrumb v-show="listShow" />
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
export default {
  name: 'Navigation',
  components: {
    Breadcrumb
  },
  data() {
    return {
      listShow: false
    }
  },
  methods: {
    hbbClick() {
      console.log(123)
      this.listShow = !this.listShow
    },
    shouye() {
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #fff;
  box-shadow: 1px 1px 10px #ccc;
  z-index: 100;
}
#navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 0.1rem 0.5rem;
}
.logo {
  height: 0.9rem;
  .logo-img {
    width: auto;
    height: 1rem;
    margin-top: -0.05rem;
  }
}
.logo-list {
  width: 0.42rem;
  height: 0.29rem;
}
</style>
