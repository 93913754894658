import Vue from 'vue'
import '@/styles/normalize.css'
import App from './App.vue'
import router from './router'
import 'lib-flexible'
import 'mint-ui/lib/style.css'
import '@/styles/index.scss'
import './permission'
import { Swipe, SwipeItem } from 'mint-ui'
import 'amfe-flexible'
Vue.prototype.$EventBus = new Vue()
Vue.component(Swipe.name, Swipe)
Vue.component(SwipeItem.name, SwipeItem)
Vue.config.productionTip = false
new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
