import router from './router'
import getPageTitle from '@/utils/get-page-title'

// 导航前置守卫
router.beforeEach((to, from, next) => {
  document.title = getPageTitle(to.meta.title)
  next()
})

// 导航后置守卫
// router.afterEach(() => {
// end progress end

// 跳转页面后 页面滚动到顶部
// document.body.scrollTop = 0
// document.documentElement.scrollTop = 0
// })
