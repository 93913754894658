<template>
  <div id="app">
    <Navigation />
    <router-view class="paddd" />
    <!-- 注释 -->
    <!-- <router-view /> -->
  </div>
</template>
<script>
import Navigation from '@/components/Navigation.vue'
export default {
  name: 'Home',
  components: { Navigation },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.paddd {
  padding-top: 1rem;
}
</style>
